
function EditIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6965 1.07741C15.0219 0.751974 15.5495 0.751974 15.875 1.07741L19.2083 4.41074C19.5338 4.73618 19.5338 5.26382 19.2083 5.58926C18.8829 5.91469 18.3552 5.91469 18.0298 5.58926L14.6965 2.25592C14.371 1.93049 14.371 1.40285 14.6965 1.07741ZM12.3995 3.5671C12.6505 3.48553 12.9209 3.48553 13.172 3.5671C13.3659 3.63009 13.5064 3.73471 13.5989 3.81329C13.6788 3.8811 13.7626 3.96495 13.8327 4.03517C13.8373 4.03978 13.8419 4.04433 13.8464 4.04882L16.2506 6.453C16.3208 6.52316 16.4046 6.60694 16.4724 6.68681C16.551 6.77937 16.6556 6.91987 16.7186 7.11373C16.8002 7.36478 16.8002 7.63522 16.7186 7.88627C16.6556 8.08013 16.551 8.22063 16.4724 8.31319C16.4046 8.39308 16.3208 8.47688 16.2505 8.54704L7.62573 17.1718C7.61517 17.1824 7.60469 17.1929 7.5943 17.2033C7.43873 17.3591 7.30143 17.4965 7.14235 17.6124C7.00236 17.7144 6.85215 17.8016 6.69414 17.8725C6.51458 17.9531 6.32711 18.0042 6.1147 18.0619C6.1005 18.0658 6.0862 18.0697 6.07177 18.0736L2.17166 19.1373C1.88315 19.216 1.57459 19.134 1.36314 18.9226C1.15168 18.7111 1.06974 18.4026 1.14842 18.1141L2.21209 14.214C2.21602 14.1995 2.21992 14.1852 2.22378 14.171C2.28157 13.9586 2.33257 13.7711 2.41319 13.5916C2.48413 13.4336 2.5713 13.2834 2.6733 13.1434C2.7892 12.9843 2.92666 12.847 3.08242 12.6914C3.09283 12.681 3.10332 12.6706 3.11389 12.66L11.7251 4.04882C11.7295 4.04434 11.7341 4.03979 11.7387 4.03519C11.8089 3.96497 11.8926 3.88111 11.9725 3.81329C12.0651 3.73471 12.2056 3.63009 12.3995 3.5671ZM12.7857 5.34518L4.2924 13.8385C4.08817 14.0427 4.04986 14.0843 4.02035 14.1248C3.98635 14.1715 3.95729 14.2216 3.93365 14.2742C3.91312 14.3199 3.89602 14.3738 3.82003 14.6525L3.14007 17.1457L5.63324 16.4657C5.91189 16.3897 5.96578 16.3726 6.0115 16.3521C6.06417 16.3284 6.11424 16.2994 6.16091 16.2654C6.20141 16.2359 6.24299 16.1975 6.44722 15.9933L14.9405 7.5L12.7857 5.34518Z"
        fill="black"
        opacity={0.6}
      />
    </svg>
  )
}

export default EditIcon
