import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'shared/button'

import { FlexOhFour, OopsText, ThisIsNotTheDroid } from './auth.styled'

function AccessDenied() {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/')
  }

  return (
    <>
      <FlexOhFour>
        <OopsText>403</OopsText>
        <ThisIsNotTheDroid>Access Denied</ThisIsNotTheDroid>
        <Button
          id="back-to-home"
          onClick={handleClick}
          variant={'primary'}
          //   size={'lgwide'}
          fontSize={'16px'}
        >
          Back to Home
        </Button>
      </FlexOhFour>
    </>
  )
}

export default AccessDenied
