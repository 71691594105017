const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID
const AUTH0_AUDIENCE = process.env.AUTH0_AUDIENCE
const AUTH0_CALLBACK_URL = process.env.AUTH0_CALLBACK_URL
const AUTH0_SCOPE = process.env.AUTH0_SCOPE

export {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  AUTH0_CALLBACK_URL,
  AUTH0_SCOPE,
}
