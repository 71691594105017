import React from 'react'
function UploadIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.74923 7.14303C6.5632 4.17816 9.2764 2 12.5 2C15.7236 2 18.4368 4.17816 19.2508 7.14303C21.6861 7.70961 23.5 9.8925 23.5 12.5C23.5 14.4079 22.5277 16.0883 21.0563 17.0732C20.5973 17.3804 19.9762 17.2574 19.669 16.7985C19.3618 16.3395 19.4848 15.7184 19.9437 15.4112C20.8842 14.7817 21.5 13.7125 21.5 12.5C21.5 10.6778 20.1067 9.17984 18.328 9.01511C17.875 8.97315 17.5073 8.63059 17.4335 8.18159C17.0436 5.80968 14.9822 4 12.5 4C10.0178 4 7.95643 5.80968 7.5665 8.18159C7.49268 8.63059 7.12505 8.97315 6.67196 9.01511C4.89329 9.17984 3.5 10.6778 3.5 12.5C3.5 13.7125 4.11575 14.7817 5.05626 15.4112C5.51521 15.7184 5.63822 16.3395 5.33101 16.7985C5.0238 17.2574 4.40269 17.3804 3.94374 17.0732C2.47228 16.0883 1.5 14.4079 1.5 12.5C1.5 9.8925 3.3139 7.70961 5.74923 7.14303ZM11.5 14.4142L9.20711 16.7071C8.81658 17.0976 8.18342 17.0976 7.79289 16.7071C7.40237 16.3166 7.40237 15.6834 7.79289 15.2929L11.7929 11.2929C12.1834 10.9024 12.8166 10.9024 13.2071 11.2929L17.2071 15.2929C17.5976 15.6834 17.5976 16.3166 17.2071 16.7071C16.8166 17.0976 16.1834 17.0976 15.7929 16.7071L13.5 14.4142V21C13.5 21.5523 13.0523 22 12.5 22C11.9477 22 11.5 21.5523 11.5 21V14.4142Z"
        fill="black"
        opacity="0.87"
      />
    </svg>
  )
}

export default UploadIcon
