import { useFormikContext } from 'formik'
import { Step2FormInputs } from '../hooks/use-step2-form.hook'
import { useEffect, useState } from 'react'

export default function FormObserver() {
  const { values }: { values: Step2FormInputs } = useFormikContext()
  const { validateForm } = useFormikContext()
  const [previousValues, setPreviousValues] = useState<Step2FormInputs>(values)

  useEffect(() => {
    if (values !== previousValues) {
      setPreviousValues(values)
      validateForm(values)
    }
  }, [values.address1, values.locationId])

  return null
}
